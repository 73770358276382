import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  selectedButton: string | null = 'Faltante';
  bgError: string = "";

  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedButton = data.error || 'Faltante';
  }

  selectButton(button: string) {
    this.selectedButton = button;
  }

  resolve(action: string) {
    if (this.selectedButton) {
      this.dialogRef.close({ option: this.selectedButton, resolved: action });
    } else {
      this.dialogRef.close(null);
    }
  }
}
