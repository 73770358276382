<div class="container flex-grow-1 container-p-y">
        <div class="row">
            <mat-card>
                <div class="col-md-12">
                    <mat-card-header>
                                <div class="col-md-12 d-flex">
                                    <div class="col-md-11">
                                      <h3>
                                        <strong>Anotar Error SKU: {{data.sku}}</strong>
                                      </h3>
                                    </div>
                                    <div class="col-md-1 d-flex justify-content-end align-items-center">
                                      <button mat-dialog-close aria-label="Cerrar" class="close-btn">
                                        <mat-icon>close</mat-icon>
                                      </button>
                                    </div>
                                </div>
                    </mat-card-header>
                    <mat-card-content class="content-error">
                        <label><strong>Error</strong></label>
                        <div class="col-md-12">
                            <button class="col-md-6" [class.selected]="selectedButton === 'Faltante'" (click)="selectButton('Faltante')"><h4><strong>Faltante</strong></h4></button>
                            <button class="col-md-6" [class.selected]="selectedButton === 'Dañado'" (click)="selectButton('Dañado')"><h4><strong>Dañado</strong></h4></button>
                        </div>
                        <div class="col-md-12">
                            <button class="col-md-6" [class.selected]="selectedButton === 'Sobrante'" (click)="selectButton('Sobrante')"><h4><strong>Sobrante</strong></h4></button>
                            <button class="col-md-6" [class.selected]="selectedButton === 'SKU Incorrecto'" (click)="selectButton('SKU Incorrecto')"><h4><strong>SKU Incorrecto</strong></h4></button>
                        </div>
                        
                    </mat-card-content>

                </div>
            </mat-card>

            <div mat-dialog-actions>
                <div class="container mt-3 mb-2">
                    <div class="row">
                        <div class="col-md-12 btn-footer">
                            <button class="col-md-4 btn btn-success btn-lg btn-resuelto" (click)="resolve('Resuelto')">
                                <mat-icon class="pt-1">check</mat-icon>
                                 <strong>Resuelto</strong>
                            </button>
                            <button class="offset-md-4 col-md-4 btn btn-danger btn-lg btn-resuelto" (click)="resolve('No Resuelto')">
                                <mat-icon class="pt-1">close</mat-icon>
                                <strong>No Resuelto</strong>
                            </button>
                        </div>            
                    </div>
    
                </div>
            </div>
        </div>
</div>

