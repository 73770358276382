<div class="container-fluid">
    <div class="page-title p-1">
        <div class="row">
            <div class="col-12">
                &nbsp;
            </div>
        </div>
    </div>
</div>
<div *ngIf="!boxOpen" class="container-fluid">
    <div class="row align-items-centerpt-5 pb-5">
        <div class="col-md-12">
            <mat-card class="card-rack"><mat-grid-list cols="1" rowHeight="2:1">
                    <mat-grid-tile>
                        <img mat-card-xl-image src="./../../../../assets/images/start.png">
                        <mat-list class="text-center">
                            <mat-list-item><h4 class="text-center">Para comenzar,</h4></mat-list-item>
                            <mat-list-item><h4 class="text-center">escanea el código de la caja.</h4></mat-list-item>
                        </mat-list>
                    </mat-grid-tile>
                  </mat-grid-list>
            </mat-card>
        </div>
    </div>
</div>

<div *ngIf="boxOpen&&userObj.tipo!=='q'&&userObj.tipo!=='bg'" class="container-fluid">
    <div class="row">
        <div class="col-md-8">
            <mat-card class="card-rack">
                <mat-card-header>
                    <mat-card-title>
                        <h5 class="fw-bold">
                            <mat-icon class="align-middle">shelves</mat-icon> Cajones del rack 
                            <span class="text-uppercase ms-3 badge bg-light"><mat-icon class="align-middle txt-primary">inventory_2</mat-icon><strong class="ms-2 txt-dark h6 mt-1 pe-1">{{box_id_title}}</strong></span>
                        </h5>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-grid-list [cols]="userObj.columnas" [rowHeight]="rowH" [gutterSize]="'5px'">
                        <div *ngFor="let mat of grid_mat; let i = index">
                            <mat-grid-tile class="border border-secondary rounded" 
                            [ngClass]="(mat.pick&&mat.cantidad&&mat.pick===mat.cantidad) ? 'bg-success' 
                            : ((mat.pick!==mat.cantidad&&mat.cantidad > 0&&mat.id!==next_scan_id&&mat.pick_omit>0) ? 'bg-warning' 
                            
                            : ((mat.pick!==mat.cantidad&&mat.cantidad > 0&&mat.id!==next_scan_id) ? 'bg-info opacity-75' 
                            : ((mat.pick!==mat.cantidad&&mat.cantidad&&mat.id==next_scan_id) ? 'bg-info vibrate' : '')))">
                                <!-- <div class="text-center" [ngClass]="{'bg-success' : mat.pick===mat.cantidad, 'bg-info' : mat.pick!==mat.cantidad&&mat.cantidad > 0, 'bg-warning opacity-75': mat.pick_omit>0}"> -->
                                    <div class="text-center" [ngClass]="mat.pick===mat.cantidad ? 'bg-success' : (mat.id===next_scan.id ? 'bg-info' : ( mat.pick_omit>0 ? 'bg-warning' : ''))">
                                    <div class="col-12" *ngIf="mat.cantidad > 0">Material requerido</div>
                                    <div class="col-12" *ngIf="mat.cantidad > 0"><span class="h2 fw-bold">{{mat.pick || 0}}</span> / <span class="h5 fw-bold">{{mat.cantidad || 0}}</span></div>
                                    <div class="col-12">
                                        <!-- <span [ngClass]="mat.cantidad > 0 ? 'badge badge-success' : ''">
                                            <span [ngClass]="mat.cantidad > 0 ? 'h5' : 'h6'">{{mat.nombre}}</span>
                                        </span> -->
                                        <div [ngClass]="mat.cantidad > 0 ? (mat.faltante==2?'bg-primary ':'bg-success ') + 'px-2 pt-1 rounded font-roboto' : ''">
                                            <label [ngClass]="mat.cantidad > 0 ? 'h5' : 'h6'">{{mat.nombre}}</label>
                                        </div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </div>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4">
            <mat-card class="card-rack" [hidden]="listMaterialsMs.length>0">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">checklist</mat-icon> Lista de verificación</h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="listMaterials" class="mat-elevation-z8">
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> Posición </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.row}} - {{element.col}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> <strong>{{element.nombre}}</strong><br><small class="opacity-75">{{element.sku}}</small> </td>
                        </ng-container>
                        <ng-container matColumnDef="sku">
                            <th mat-header-cell *matHeaderCellDef [hidden]="true"> SKU </th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6" [hidden]="true"> {{element.sku}} </td>
                          </ng-container>
                        <ng-container matColumnDef="pick">
                          <th mat-header-cell *matHeaderCellDef> Hecho </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6">{{element.pick}} / {{element.cantidad}}</td>
                        </ng-container>
                        <ng-container matColumnDef="faltante">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6"> 
                              <button *ngIf="element.faltante==2" mat-icon-button matTooltip="Material faltante" [matTooltipPosition]="'above'" color="warn">
                                  <mat-icon>warning</mat-icon>
                              </button>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>                        
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.id===next_scan.id ? 'bg-info' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-info opacity-50'))"></tr> -->
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.faltante==2?(row.id===next_scan.id ? 'bg-primary' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-primary opacity-75')):(row.id===next_scan.id ? 'bg-info' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-info opacity-50')))"></tr>
                    </table>
                </mat-card-content>
            </mat-card>
            <mat-card class="card-mid-rack" [hidden]="listMaterialsMs.length==0">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">checklist</mat-icon> Lista de verificación</h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="listMaterialsAv" class="mat-elevation-z8">
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> Posición </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.row}} - {{element.col}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> <strong>{{element.nombre}}</strong><br><small class="opacity-75">{{element.sku}}</small> </td>
                        </ng-container>
                        <ng-container matColumnDef="sku">
                            <th mat-header-cell *matHeaderCellDef [hidden]="true"> SKU </th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6" [hidden]="true"> {{element.sku}} </td>
                          </ng-container>
                        <ng-container matColumnDef="pick">
                          <th mat-header-cell *matHeaderCellDef> Hecho </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6">{{element.pick}} / {{element.cantidad}}</td>
                        </ng-container>
                        <ng-container matColumnDef="faltante">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6"> 
                              <button *ngIf="element.faltante==2" mat-icon-button matTooltip="Material faltante" [matTooltipPosition]="'above'" color="warn">
                                  <mat-icon>warning</mat-icon>
                              </button>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>                        
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.id===next_scan.id ? 'bg-info' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-info opacity-50'))"></tr>
                    </table>
                </mat-card-content>
            </mat-card>
            <mat-card class="card-mid-rack mt-4" [hidden]="listMaterialsMs.length==0">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">checklist</mat-icon> Lista de faltantes</h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="listMaterialsMs" class="mat-elevation-z8">
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> Posición </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.row}} - {{element.col}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> <strong>{{element.nombre}}</strong><br><small class="opacity-75">{{element.sku}}</small> </td>
                        </ng-container>
                        <ng-container matColumnDef="sku">
                            <th mat-header-cell *matHeaderCellDef [hidden]="true"> SKU </th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6" [hidden]="true"> {{element.sku}} </td>
                          </ng-container>
                        <ng-container matColumnDef="pick">
                          <th mat-header-cell *matHeaderCellDef> Hecho </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6">{{element.pick}} / {{element.cantidad}}</td>
                        </ng-container>
                        <ng-container matColumnDef="faltante">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6"></td>
                        </ng-container>
                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>                        
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.id===next_scan.id ? 'bg-primary' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-primary opacity-50'))"></tr>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div *ngIf="boxOpen&&userObj.tipo==='bg'" class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <mat-card class="card-rack" [hidden]="listMaterialsMs.length>0&&false">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">checklist</mat-icon> Lista de verificación <span class="text-uppercase ms-3 badge bg-light"><mat-icon class="align-middle txt-primary">inventory_2</mat-icon><strong class="ms-2 txt-dark h6 mt-1 pe-1">{{box_id_title}}</strong></span></h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="listMaterials" class="mat-elevation-z8">
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> Posición </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.row}} - {{element.col}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> <strong>{{element.nombre}}</strong> </td>
                        </ng-container>
                        <ng-container matColumnDef="sku">
                            <th mat-header-cell *matHeaderCellDef> SKU </th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.sku}} </td>
                          </ng-container>
                        <ng-container matColumnDef="pick">
                          <th mat-header-cell *matHeaderCellDef> Hecho </th>
                          <td mat-cell *matCellDef="let element" class="txt-light h6"> {{element.pick}} / {{element.cantidad}} </td>
                        </ng-container>
                        <ng-container matColumnDef="faltante">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="txt-light h6"> 
                                <span [hidden]="element.faltante!=2" class="badge badge-danger">Faltante</span>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="(next_scan&&row.id===next_scan.id) ? 'bg-info' : (row.complete||row.pick===row.cantidad)&&(row.pick_omit===0||!row.pick_omit) ? 'bg-success' : (row?.pick_omit>0) ? 'bg-warning' : 'bg-light-info'"></tr>
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.faltante==2?(row.id===next_scan.id ? 'bg-primary' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-primary opacity-75')):(row.id===next_scan.id ? 'bg-info' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-info opacity-50')))"></tr> -->
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>


<div *ngIf="userObj.tipo==='q'" [hidden]="!boxOpen" class="container-fluid">
    <div class="row">
        <div [ngClass]="box_readonly?'col-md-12':'col-md-8'">
            <mat-card class="card-rack">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">checklist</mat-icon> Lista de verificación <span class="text-uppercase ms-3 badge bg-light"><mat-icon class="align-middle txt-primary">inventory_2</mat-icon><strong class="ms-2 txt-dark h6 mt-1 pe-1">{{box_id_title}}</strong></span></h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="listMaterials" class="mat-elevation-z8">
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef> Position </th>
                          <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> {{element.position}} </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> {{element.nombre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="sku">
                          <th mat-header-cell *matHeaderCellDef> SKU </th>
                          <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> {{element.sku}} </td>
                        </ng-container>                        
                        <ng-container matColumnDef="pick">
                          <th mat-header-cell *matHeaderCellDef> Hecho </th>
                          <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> {{element.pick}} / {{element.cantidad}} </td>
                        </ng-container>
                        <ng-container matColumnDef="faltante">
                            <th mat-header-cell *matHeaderCellDef>Semáforo</th>
                            <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> 
                                <span [hidden]="element.faltante!=2" class="badge badge-danger">Faltante</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef>Error</th>
                            <td mat-cell *matCellDef="let element" class="h6 fw-bold txt-light"> 
                                <span class="badge" [ngClass]="{'badge-success-dark': element.resolved === 'Resuelto', 'badge-danger': element.resolved === 'No Resuelto'}">{{element.error}}</span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row (click)="openDialogError(row)" *matRowDef="let row; columns: displayedColumns;" [ngClass]="(next_scan&&row.id===next_scan.id) ? 'bg-info' : (row.complete||row.pick===row.cantidad)&&(row.pick_omit===0||!row.pick_omit) ? 'bg-success' : (row?.pick_omit>0) ? 'bg-warning' : 'bg-light-info'"></tr>
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.pick===row.cantidad ? 'bg-success' : (row.faltante==2?(row.id===next_scan.id ? 'bg-primary' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-primary opacity-75')):(row.id===next_scan.id ? 'bg-info' : ( row.pick_omit>0 ? 'bg-warning' : 'bg-info opacity-50')))"></tr> -->
                        
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-4" [hidden]="box_readonly">
            <mat-card class="card-rack">
                <mat-card-header>
                    <mat-card-title><h5 class="fw-bold"><mat-icon class="align-middle">videocam</mat-icon> Vista de video</h5></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div style="text-align:center">
                        <video class="video" #video autoplay controls capture="camera" class="w-100"></video>
                      </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div *ngIf="boxOpen" class="container-fluid pt-3">
    <div class="row">
        <div class="col-md-12">
            <mat-card class="card-rack col-md-12">
                <mat-card-content>
                    <label *ngFor="let st of line_status; let s = index" class="px-3 py-2 text-capitalize rounded me-2" [ngClass]="{'bg-success' : st.status===1, 'bg-info' : st.status===0, 'bg-warning' : st.status===2 }"> {{ st.nombre }} </label>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>